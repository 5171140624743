import {
  GetTeamsWithScoreResponseModel,
  ScoreSummary,
  insightsAPI,
} from '@capturi/api-insights'
import { Score } from '@capturi/api-scoring'
import { Team, useTeams } from '@capturi/core'
import {
  Segment,
  useFetchSegments,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import { PlaySnippetsButton } from '@capturi/ui-components'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { useStore } from '@tanstack/react-store'
import { DataGrid, DataGridColumn } from 'components/DataGrid'
import orderBy from 'lodash/orderBy'
import UserBreakdownSkeleton from 'pages/analytics/shared/components/UserBreakdownSkeleton'
import React from 'react'

import { DATA_SOURCE } from '../constants'
import { useScoreConversationsDrawer } from '../drawers'
import { logEvent } from '../events'
import { useOnRevalidateDataSource } from '../hooks/useRevalidateDataSource'
import { selectedTeamsStore } from '../state/selectedTeamsState'

const hasHits = (d: TeamData): boolean =>
  d.segments.some((x) => x.data?.hits && x.data.hits > 0)

export type TeamHitsListProps = {
  score: Score
  selectedRange?: number[]
  selectableRows?: boolean
  segmentSummaries?: Segment<ScoreSummary>[]
}

const TeamHitsList = React.memo<TeamHitsListProps>(function UserHitsMemo({
  score,
  selectedRange,
  selectableRows = false,
  segmentSummaries,
}) {
  const { phoneSegmentStates: states } = useSegmentStatesContext()
  const { periodDef } = useFilterPeriodContext()
  const selectedTeams = useStore(selectedTeamsStore)
  const openConversationsDrawer = useScoreConversationsDrawer()

  const { segments, isLoading, revalidate } =
    useFetchSegments<GetTeamsWithScoreResponseModel>(
      () => insightsAPI.getTeamsWithScore(score.uid),
      (state, _, periodDef, mapperFn) => {
        return mapperFn(
          {
            ...state.values,
            scores: [
              ...(state.values.scores ?? []),
              {
                uid: score.uid,
                min: selectedRange?.[0],
                max: selectedRange?.[1],
              },
            ],
          },
          periodDef,
        )
      },
    )

  useOnRevalidateDataSource(DATA_SOURCE, () => {
    revalidate()
  })

  const rowData = useRowData(segments)

  const handleSelectRows = React.useCallback((rows: TeamData[]) => {
    const teams = rows.map((x) => x.team.uid)
    selectedTeamsStore.setState(() => teams)
  }, [])

  const handleViewUserConversations = React.useCallback(
    (segmentIndex: number, row: TeamData): void => {
      const team = row.team
      const segment = row.segments[segmentIndex]
      const filterState = states[segmentIndex].values
      openConversationsDrawer({
        label: team.name,
        title: t`Conversations`,
        score,
        selectedRange,
        periodDef,
        filterState: {
          ...filterState,
          teamUids: [team.uid],
        },
        excludeDeletedConversations: false,
        segmentColorScheme: segment.color,
      })
      logEvent('view-conversations-drawer--clicked')
    },
    [openConversationsDrawer, states, score, periodDef, selectedRange],
  )

  const columns = React.useMemo(() => {
    const formatAverageScore = (val: number | undefined): string => {
      return val != null
        ? i18n.number(val, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          })
        : ''
    }

    const columns: DataGridColumn<TeamData>[] = [
      {
        type: 'team',
        getValue: (d) => d.team.uid,
        width: 200,
      },
      {
        type: 'value',
        id: 'avg-score',
        getSegmentValue: (s) => s.data?.averageScore,
        formatValue: formatAverageScore,
        alignRight: true,
      },
      {
        type: 'progress',
        getSegmentValue: (s) => s.data?.averageScore,
        formatValue: formatAverageScore,
        range: [0, score.maxScore],
        referenceLines: segmentSummaries?.reduce<
          { value: number; color: string; label: string }[]
        >((memo, s) => {
          if (s.data != null) {
            memo.push({
              label: t`Avg. ${i18n.number(s.data.averageScore, {
                maximumFractionDigits: 1,
              })}`,
              color: s.color,
              value: s.data.averageScore,
            })
          }
          return memo
        }, []),
      },
      {
        width: 40,
        type: 'dataBasis',
        getSegmentValue: (s) => {
          const { hits = 0 } = s.data ?? {}
          return {
            value: hits,
          }
        },
      },
      {
        type: 'button',

        render: (row, segmentIndex) => (
          <PlaySnippetsButton
            label={t`View conversations`}
            onClick={() => {
              if (!row.team.uid) return
              handleViewUserConversations(segmentIndex, row)
            }}
          />
        ),
      },
    ]
    return columns
  }, [handleViewUserConversations, score, segmentSummaries])

  const initialSelectedRowsRef = React.useRef(() => {
    return rowData.reduce<Record<string, boolean>>((memo, row, i) => {
      if (selectedTeams.indexOf(row.team.uid) !== -1) {
        memo[i] = true
      }
      return memo
    }, {})
  })

  return (
    <UserBreakdownSkeleton isLoaded={!isLoading}>
      <DataGrid
        data={rowData}
        columns={columns}
        selectableRows={selectableRows}
        initialSelectedRows={initialSelectedRowsRef.current}
        onSelectedRowsChange={handleSelectRows}
        isRowSelectable={hasHits}
      />
    </UserBreakdownSkeleton>
  )
})

export default TeamHitsList

export type Datum = {
  conversations: number
  hits: number
  hitRate: number
  averageScore: number
}

export type TeamData = {
  team: Team
  segments: Segment<Datum>[]
}

function useRowData(
  segments: Segment<GetTeamsWithScoreResponseModel>[],
): TeamData[] {
  const { getTeamByUid } = useTeams()

  return React.useMemo(() => {
    const allTeamUids = new Set(
      segments.flatMap((s) => (s.data?.teams ?? []).map((x) => x.teamUid)),
    )

    const hitMaps = segments.map<{ [key: string]: Datum }>((s) => {
      return (s.data?.teams ?? []).reduce<{
        [key: string]: Datum
      }>((acc, x) => {
        acc[x.teamUid] = {
          conversations: s.data?.conversationsTotal ?? 0,
          hits: x.conversations,
          hitRate: x.conversationsPercent / 100,
          averageScore: x.averageScore,
        }
        return acc
      }, {})
    })

    const segmentData = [...allTeamUids].reduce<{ [key: string]: TeamData }>(
      (acc, teamUid) => {
        acc[teamUid] = {
          segments: segments.map<Segment<Datum>>((s, i) => {
            const map = hitMaps[i]
            return {
              color: s.color,
              label: s.label,
              data: map[teamUid] ?? {
                conversations: 0,
                hits: 0,
                hitRate: 0,
                averageScore: 0,
              },
            }
          }),
          team: getTeamByUid(teamUid) ?? {
            uid: teamUid,
            name: 'N/A',
            members: [],
            isDeleted: false,
          },
        }
        return acc
      },
      {},
    )

    const data = orderBy(
      Object.values(segmentData),
      [(x) => x.segments[0]?.data?.averageScore, (x) => x.team.name],
      ['desc', 'asc'],
    )

    return data
  }, [segments, getTeamByUid])
}

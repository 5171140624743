import {
  GetScoreParametersResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import { Score, ScoreParameter } from '@capturi/api-scoring'
import {
  Segment,
  useFetchSegments,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import { getFilterInfo } from '@capturi/scoring'
import { PlaySnippetsButton } from '@capturi/ui-components'
import { t } from '@lingui/macro'
import { DataGrid, DataGridColumn } from 'components/DataGrid'
import orderBy from 'lodash/orderBy'
import UserBreakdownSkeleton from 'pages/analytics/shared/components/UserBreakdownSkeleton'
import React from 'react'

import { DATA_SOURCE } from '../constants'
import { useScoreParameterConversationsDrawer } from '../drawers'
import { logEvent } from '../events'
import { useOnRevalidateDataSource } from '../hooks/useRevalidateDataSource'
import { formatHitRate } from '../utils/formatHitRate'

export type ParameterHitsListProps = {
  score: Score
  selectedRange?: number[]
}

function getLargestSegmentValueLength(
  segmentData: GetScoreParametersResponseModel | null,
): number | undefined {
  if (!segmentData) return
  const totalConversations = segmentData.conversationsTotal
  const largestNumber = segmentData.parameters.reduce(
    (acc, data) => (acc < data.conversations ? data.conversations : acc),
    0,
  )
  const pixelPerCharacter = 7
  return `${largestNumber}/${totalConversations}`.length * pixelPerCharacter
}

const ParameterHitsList = React.memo<ParameterHitsListProps>(
  function ParameterHitsListMemo({ score, selectedRange }) {
    const { phoneSegmentStates: states } = useSegmentStatesContext()
    const { periodDef } = useFilterPeriodContext()
    const openConversationsDrawer = useScoreParameterConversationsDrawer()

    const { segments, isLoading, revalidate } =
      useFetchSegments<GetScoreParametersResponseModel>(
        () => insightsAPI.getScoreParameters(score.uid),
        (state, _, periodDef, mapperFn) => {
          return mapperFn(
            {
              ...state.values,
              scores: [
                ...(state.values.scores ?? []),
                {
                  uid: score.uid,
                  min: selectedRange?.[0],
                  max: selectedRange?.[1],
                },
              ],
            },
            periodDef,
          )
        },
      )

    const rowData = useParameterHitsData(score, segments)

    useOnRevalidateDataSource(DATA_SOURCE, () => {
      revalidate()
    })

    const handleViewParameterConversations = React.useCallback(
      (segmentindex: number, parameterUid: string): void => {
        openConversationsDrawer({
          score,
          parameterUid,
          parameterHasScore: true,
          selectedRange,
          periodDef,
          filterState: states[segmentindex].values,
          excludeDeletedConversations: false,
        })
        logEvent('view-conversations-drawer--clicked')
      },
      [openConversationsDrawer, states, score, periodDef, selectedRange],
    )

    const columns = React.useMemo(() => {
      const columns: DataGridColumn<ParameterRow>[] = [
        {
          type: 'value',
          getValue: (d) => d.filterInfo.label,
          width: 200,
        },
        {
          type: 'value',
          id: 'hit-rate',
          getSegmentValue: (s) => s.data?.hitRate,
          formatValue: formatHitRate,
          alignRight: true,
        },
        {
          type: 'progress',
          getSegmentValue: (s) => s.data?.hitRate,
          formatValue: formatHitRate,
          range: [0, 1],
        },
        {
          type: 'dataBasis',
          width: getLargestSegmentValueLength(segments[0].data),
          getSegmentValue: (s) => {
            const { hits = 0, conversations: count = 0 } = s.data ?? {}
            return {
              value: hits,
              total: count,
            }
          },
        },
        {
          type: 'button',

          render: (row, segmentIndex) => (
            <PlaySnippetsButton
              label={t`View conversations`}
              onClick={() => {
                if (!row.parameter.uid) return
                handleViewParameterConversations(
                  segmentIndex,
                  row.parameter.uid,
                )
              }}
            />
          ),
        },
      ]
      return columns
    }, [handleViewParameterConversations, segments])

    return (
      <UserBreakdownSkeleton isLoaded={!isLoading}>
        <DataGrid data={rowData} columns={columns} />
      </UserBreakdownSkeleton>
    )
  },
)

export default ParameterHitsList

type Datum = {
  conversations: number
  hits: number
  hitRate: number
}

type ParameterRow = {
  parameter: ScoreParameter
  segments: Segment<Datum>[]
  filterInfo: ReturnType<typeof getFilterInfo>
}

function useParameterHitsData(
  score: Score,
  segments: Segment<GetScoreParametersResponseModel>[],
): ParameterRow[] {
  return React.useMemo(() => {
    const segmentHitMaps = segments.map<{ [key: string]: Datum }>((s) => {
      return (s.data?.parameters ?? []).reduce<{
        [key: string]: Datum
      }>((acc, x) => {
        acc[x.parameterUid] = {
          conversations: s.data?.conversationsTotal ?? 0,
          hits: x.conversations,
          hitRate: x.conversationsPercent / 100,
        }
        return acc
      }, {})
    })

    const data = score.parameters.map<ParameterRow>((parameter) => {
      return {
        parameter: parameter,
        filterInfo: getFilterInfo(parameter),
        segments: segments.map<Segment<Datum>>((s, i) => {
          const hitMap = segmentHitMaps[i]
          const data = parameter.uid ? hitMap[parameter.uid] : null
          return {
            color: s.color,
            label: s.label,
            data: data ?? {
              conversations: 0,
              hits: 0,
              hitRate: 0,
            },
          }
        }),
      }
    })
    return orderBy(
      data,
      [(x) => x.segments[0]?.data?.hitRate, (x) => x.parameter.weight],
      ['desc', 'desc'],
    )
  }, [segments, score])
}
